import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../../components';
import Layout from '../../components/layout';
import { GalleryMain, GalleryNav } from '../../components/Pages/Gallery';
import SEO from '../../components/seo';
import styles from '../../stylesheets/pages/page.module.scss';
import { QUICK_NAVIGATION_GALLERY, getGalleryMainListData, getLocalizedDataFromContentfulEdges } from '../../utils';

const PressMaterial = () => {
	const intl = useIntl();
	const { allContentfulGallery, allContentfulSpecificPageMetaData } = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "media-gallery" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
			allContentfulGallery {
				edges {
					node {
						node_locale
						name
						path
						packageUrl
						background {
							fixed(width: 1200) {
								src
							}
						}
						assets {
							... on ContentfulGalleryDocument {
								file {
									file {
										url
									}
								}
							}
						}
					}
				}
			}
		}
	`);
	const { node } = getLocalizedDataFromContentfulEdges(allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	const galleryMain = getLocalizedDataFromContentfulEdges(allContentfulGallery.edges, intl.locale);
	const galleryMainListData = getGalleryMainListData(galleryMain);
	return (
		<>
			<SEO lang={intl.locale} title={node.title} description={node.description}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_GALLERY}>
					<div className={styles.pageContent}>
						<ContentLayout>
							<GalleryNav />
						</ContentLayout>
						<GalleryMain listData={galleryMainListData} />
					</div>
				</QuickBottomNavigationWrapper>
			</Layout>
		</>
	);
};

export default PressMaterial;
